import { Documento, InputUpoloadDocumento, PageDocumentoInput } from '@/models/documento'
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { print } from "graphql";

import store from "@/store"
import { Pagination } from './pagination';

const documentoPage = require('@/query/documento/Page.graphql')

export class DocumentoAPI extends BaseAPI<Documento> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/documents`;
  }

  public download(body: Documento): AxiosObservable<any> {
    return this.axios.get(`${this.backUrl}/${body.id}/download`, {
      params: { attachment: true },
      responseType: "blob"
    });
  }

  public upload(body: InputUpoloadDocumento): AxiosObservable<any> {
    let formData = new FormData()
    formData.append("file", body.file);
    formData.append("typeDocument", `${body.typeDocument}`);
    formData.append("solicitation", `${body.solicitation}`);

    return this.axios.post(`${this.backUrl}`, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      onUploadProgress: ({total, loaded}) => {
        const percentage = Math.round((loaded * 100) / total)
        store.commit('cadastroEmpresa/SET_DOCUMENT_FORM',{typeDocumentKey: body.typeDocumentKey, doc: {progress: percentage}})
      }
    },);
  }

  public page(
    input: PageDocumentoInput
  ): AxiosObservable<Pagination<Documento>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(documentoPage),
        variables: { ...input }
      })
  }
}

import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { PedidoAPI } from "@/api/pedido.api";
import {
  PagePedidoInput,
  Pedido,
  PayloadCreatePedido,
  PayloadCreateRenovacaoCRLV,
  PayloadCreateCompany,
  PayloadRenovacaoCertificadoTacografo,
} from "@/models/pedido";
import { Pagination } from "@/api/pagination";
import { of, Observable, async } from "rxjs";
import { map } from "rxjs/operators";
import { InputUpoloadDocumento } from "@/models/documento";
import {
  PayloadInclusaoVeiculo,
  PayloadEnvioDocumentacaoSeguro,
} from "@/models/veiculo";
import {
  PayloadAutoVistoria,
  PayloadLaudoEngenheiroMecanico,
  PayloadRenovacaoCadastroEmpresa,
} from "@/models/company";

const pedidoAPI = new PedidoAPI();

const page = (input: PagePedidoInput): Observable<Pagination<Pedido>> => {
  return pedidoAPI.page(input).pipe(
    map((data: any) => {
      const {
        pageSolicitations: { items, totalItems },
      } = data;
      return { items, totalItems };
    })
  );
};

const show = (id: number): Observable<Pedido> => {
  return pedidoAPI.show(id).pipe(
    map((data: any) => {
      const { showSolicitation } = data;
      return showSolicitation;
    })
  );
};

const create = (body: PayloadCreatePedido): Observable<Pedido> => {
  return pedidoAPI.createPedido(body).pipe(map((data: any) => ({ ...data })));
};

const createRenovacaoCrlv = (
  body: PayloadCreateRenovacaoCRLV
): Observable<Pedido> => {
  return pedidoAPI
    .createRenovacaoCrlv(body)
    .pipe(map((data: any) => ({ ...data })));
};

const uploadDocument = (
  pedidoID: number,
  body: InputUpoloadDocumento
): Promise<any> => {
  return pedidoAPI.uploadDocument(pedidoID, body).toPromise();
};

const createCompany = (body: PayloadCreateCompany): Observable<Pedido> => {
  return pedidoAPI.createCompany(body).pipe(map((data: any) => ({ ...data })));
};

const inclusaoVeiculo = (body: PayloadInclusaoVeiculo): Observable<Pedido> => {
  return pedidoAPI
    .inclusaoVeiculo(body)
    .pipe(map((data: any) => ({ ...data })));
};

const exclusaoVeiculo = (body: PayloadInclusaoVeiculo): Observable<Pedido> => {
  return pedidoAPI
    .exclusaoVeiculo(body)
    .pipe(map((data: any) => ({ ...data })));
};

const criarAutoVistoria = (body: PayloadAutoVistoria): Observable<Pedido> => {
  return pedidoAPI
    .criarAutoVistoria(body)
    .pipe(map((data: any) => ({ ...data })));
};

const renovacaoCertificadoTacografo = (
  body: PayloadRenovacaoCertificadoTacografo
): Observable<Pedido> => {
  return pedidoAPI
    .renovacaoCertificadoTacografo(body)
    .pipe(map((data: any) => ({ ...data })));
};

const criarLaudoEngenheiroMecanico = (
  body: PayloadLaudoEngenheiroMecanico
): Observable<Pedido> => {
  return pedidoAPI
    .criarLaudoEngenheiroMecanico(body)
    .pipe(map((data: any) => ({ ...data })));
};

const criarEnvioDocumentacaoSeguro = (
  body: PayloadEnvioDocumentacaoSeguro
): Observable<Pedido> => {
  return pedidoAPI
    .criarEnvioDocumentacaoSeguro(body)
    .pipe(map((data: any) => ({ ...data })));
};

const criarRenovacaoCadastroEmpresa = (
  body: PayloadRenovacaoCadastroEmpresa
): Observable<Pedido> => {
  return pedidoAPI
    .criarRenovacaoCadastroEmpresa(body)
    .pipe(map((data: any) => ({ ...data })));
};

const createPedidos = (body: object): Observable<any> => {
  return pedidoAPI.createPedidos(body).pipe(map((data: any) => data));
};
const detailsPedidos = (group: string): Observable<any> => {
  return pedidoAPI.detailsPedidos(group).pipe(map((data: any) => data));
};

const detailsPedidosByGroup = (group: string): Observable<any> => {
  return pedidoAPI.detailsPedidosByGroup(group).pipe(map((data: any) => data));
};

const detailsListPayments = (group: string): Observable<any> => {
  return pedidoAPI.detailsListPayments(group).pipe(map((data: any) => data));
};

const detailsAutovistoria = (key:string): Observable<any> => {
  return pedidoAPI.detailsAutovistoria(key).pipe(map((data:any) => data));

}

const updateEvidences = (key: string, formData: object) => {
  return pedidoAPI.updateEvidences(key,formData)
}

const getRequirementByKey = (key: string) => {
  return pedidoAPI.getByKey(key)
}

const contentDetach = (key: string) => {
  return pedidoAPI.contentDetach(key)
}

export default {
  page,
  show,
  create,
  uploadDocument,
  createRenovacaoCrlv,
  createCompany,
  inclusaoVeiculo,
  exclusaoVeiculo,
  criarAutoVistoria,
  criarLaudoEngenheiroMecanico,
  criarRenovacaoCadastroEmpresa,
  criarEnvioDocumentacaoSeguro,
  renovacaoCertificadoTacografo,
  createPedidos,
  detailsPedidos,
  detailsPedidosByGroup,
  detailsListPayments,
  detailsAutovistoria,
  updateEvidences,
  getRequirementByKey,
  contentDetach
};

export default {
  SET_IS_LOADING_FORM: (state: any, value: boolean) => {
    state.form.isLoading = value;
  },
  SET_CURRENT_STEP_FORM: (state: any, value: number) => {
    state.form.currentStep = value;
  },
  SET_DATA_FORM: (state: any, value: any) => {
    state.form.data = value;
  },
  SET_CLEAR_FORM: (state: any) => {
    state.form = {
      currentStep: 1,
      isLoading: false,
      data: null,
      documentos: [],
      error: null,
      placas: [],
      veiculosDocumentos: [],
      message: [{active:false, msg:"",icon:"",color:""}]
    };
  },
  SET_DOCUMENTS_FORM: (state: any, value: Array<any>) => {
    state.form.documentos = value;
  },
  SET_DOCUMENT_FORM: (state: any, { typeDocumentKey, doc }: any) => {
    const docs = state.form.documentos.map((d: any) => {
      return d.typeDocumentKey === typeDocumentKey ? { ...d, ...doc } : d;
    });
    state.form.documentos = docs;
  },
  SET_ERROR_FORM: (state: any, value: any) => {
    state.form.error = value;
  },
  SET_FORM_VEICULOS: (state: any, value: any) => {
    state.form.placas = value;
  },
  SET_FORM_DOC: (state: any, value: any) => {
    state.form.veiculosDocumentos = value;
  },
  SET_MESSAGE: (state: any, value: any) => {
    state.form.message = value;
  },
};

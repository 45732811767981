import { PagamentoAPI } from "@/api/pagamento.api";
import { Pagamento, PayloadPagamento } from '@/models/pagamento';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';

const api = new PagamentoAPI();

const create = (body: PayloadPagamento): AxiosObservable<Pagamento> => {
  return api.createPagamento(body);
}

const createPaymentGroup = (body: any) : AxiosObservable<any> =>{
  return api.createPaymentGroup(body)
}

export default {
  create,
  createPaymentGroup
}

export enum AcaoAcompanhamentoEnum {
  CRIADO_PEDIDO = 'Pedido Criado',
  ATUALIZADO_PEDIDO = 'Pedido Atualizado',
  DELETADO_PEDIDO = 'Pedido Deletado',
  UPLOAD_DOCUMENTO = 'Upload Documento',
  ATUALIZADO_DOCUMENTO = 'Documento Atualizado',
  DELETADO_DOCUMENTO = 'Documento Excluído',
  APROVADO_DOCUMENTO = 'Documento Aprovado',
  REPROVADO_DOCUMENTO = 'Documento Atualizado',
  CRIADO_PAGAMENTO = 'Pagamento Criado',
  ATUALIZADO_PAGAMENTO = 'Pagamento Atualizado'
}

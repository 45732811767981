import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { DocumentoAPI } from '@/api/documento.api';
import { Documento, InputUpoloadDocumento, PageDocumentoInput } from '@/models/documento';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '@/api/pagination';

const api = new DocumentoAPI();

const download = (body: Documento): AxiosObservable<any> =>
api.download(body);

const doDownloadFile = (data: any, file: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", file);
  document.body.appendChild(link);
  link.click();
};

const upload = (data: InputUpoloadDocumento): Observable<Documento> => api.upload(data).pipe(map((data: any) => ({...data})))

const page = (input: PageDocumentoInput): Observable<Pagination<Documento>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageDocuments: { items, totalItems } } = data;
    return { items, totalItems }
  }));
}

export default {
  download,
  doDownloadFile,
  upload,
  page
}

import { Documento } from '@/models/documento'
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagamento, PayloadPagamento } from '@/models/pagamento';

export class PagamentoAPI extends BaseAPI<Pagamento> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/payments`;
    this.apiUrl = `${this.apiUrl}/api_app/v1/payments`;
  }

  public createPagamento(body: PayloadPagamento): AxiosObservable<Pagamento> {
    return this.axios.post(`${this.backUrl}`, body)
  }

  public createPaymentGroup(body: Pagamento): AxiosObservable<any> {

    return this.axios.post(`${this.apiUrl}/bulks`,body)

  }
}

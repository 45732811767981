export enum StatusDocumento {
  NAO_AVALIADO = 'Não avaliado',
  ACEITO = 'Aceito',
  REJEITADO = 'Rejeitado'
}

export enum StatusDocumentoEnum{
  NAO_AVALIADO = 'NAO_AVALIADO',
  ACEITO = 'ACEITO',
  REJEITADO = 'REJEITADO'
}

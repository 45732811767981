import { User } from '@/models/user'
import { BaseAPI } from '@/api/base.api'
import { BACK_URL_SERVICE } from '@/commons/config'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'

export class UserAPI extends BaseAPI<User> {
  public signin (cnpj: string, token: string, concordo: boolean): AxiosObservable<User> {
    return this.axios.post(`${BACK_URL_SERVICE}/auth/companies/login`, { cnpj, token, concordo})
  }
}

import Vue from 'vue'
import dayjs from 'dayjs';
import { TipoPedido } from '@/models/tipo.enum';
import { StatusPedidoEnum } from '@/models/status-pedido.enum';
import { StatusPayment } from '@/models/status-payment.enum';
import { AcaoAcompanhamentoEnum } from '@/models/acao-acompanhamento.enum';
import { TipoDocumento } from "@/models/tipo-documento.enum"
import { StatusDocumento } from "@/models/status-documento.enum"

/**
 * Filtro de data, modifica o formato da data para o padrão de data Brasil
 * Ex.: 1994-02-11 para 11/02/1994
 */
const date = Vue.filter("date", (value: any, format: string = "DD/MM/YYYY" ) => {
  if (!value) return null;

  return dayjs(value).format(format)
});


/**
 * Filtro de tipo de pedido
 */
const tipoPedido = Vue.filter("tipoPedido", (value: any) => {
  if (!value) return null;

  return TipoPedido[value]
});

/**
 * Filtro de status de pedido
 */
const statusPedido = Vue.filter("statusPedido", (value: any) => {
  if (!value) return null;

  return StatusPedidoEnum[value]
});


const statusPagamento = Vue.filter("statusPagamento",  (value: any) => {
  if (!value) return null;

  return StatusPayment[value]
});

const statusDocumento = Vue.filter("statusDocumento",  (value: any) => {
  if (!value) return null;

  return StatusDocumento[value]
});

const tipoDocumento = Vue.filter("tipoDocumento",  (value: any) => {
  if (!value) return null;

  return TipoDocumento[value]
});

const acaoAcompanhamento = Vue.filter("acaoAcompanhamento",  (value: any) => {
  if (!value) return null;

  return AcaoAcompanhamentoEnum[value]
});

export default {
  date,
  tipoPedido,
  statusPedido,
  statusPagamento,
  acaoAcompanhamento,
  tipoDocumento,
  statusDocumento
}



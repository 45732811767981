import { print } from "graphql";
import {
  Pedido,
  PagePedidoInput,
  PayloadCreatePedido,
  PayloadCreateRenovacaoCRLV,
  PayloadCreateCompany,
  PayloadRenovacaoCertificadoTacografo,
} from "@/models/pedido";
import { BaseAPI } from "@/api/base.api";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { Pagination } from "./pagination";
import { map } from "rxjs/operators";
import { InputUpoloadDocumento } from "@/models/documento";

const pedidoPage = require("@/query/pedido/Page.graphql");
const pedidoShow = require("@/query/pedido/Show.graphql");

import store from "@/store";
import {
  PayloadInclusaoVeiculo,
  PayloadEnvioDocumentacaoSeguro,
} from "@/models/veiculo";
import {
  PayloadAutoVistoria,
  PayloadLaudoEngenheiroMecanico,
  PayloadRenovacaoCadastroEmpresa,
} from "@/models/company";

export class PedidoAPI extends BaseAPI<Pedido> {
  private apiUrlPayments: string;
  constructor() {
    super();
    // this.apiUrlPayments = `${this.apiUrl}/api_app/v1/`;
    this.backUrl = `${this.backUrl}/solicitations`;
    this.apiUrl = `${this.apiUrl}/api_app/v1`;
  }

  public page(input: PagePedidoInput): AxiosObservable<Pagination<Pedido>> {
    return this.axios.post(`${this.graphqlUrl}`, {
      query: print(pedidoPage),
      variables: { ...input },
    });
  }

  public createPedido(body: PayloadCreatePedido): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}`, { ...body });
  }

  public createRenovacaoCrlv(
    body: PayloadCreateRenovacaoCRLV
  ): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/renovacao-crlv`, { ...body });
  }

  public createCompany(body: PayloadCreateCompany): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/cadastro-empresa`, { ...body });
  }

  public inclusaoVeiculo(
    body: PayloadInclusaoVeiculo
  ): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/inclusao-veiculo`, { ...body });
  }

  public exclusaoVeiculo(
    body: PayloadInclusaoVeiculo
  ): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/exclusao-veiculo`, { ...body });
  }

  public criarAutoVistoria(body: PayloadAutoVistoria): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/autovistoria`, { ...body });
  }

  public renovacaoCertificadoTacografo(
    body: PayloadRenovacaoCertificadoTacografo
  ): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/renovacao-certificado-tacografo`, {
      ...body,
    });
  }

  public criarLaudoEngenheiroMecanico(
    body: PayloadLaudoEngenheiroMecanico
  ): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/laudo-engenheiro-mecanico`, {
      ...body,
    });
  }

  public criarEnvioDocumentacaoSeguro(
    body: PayloadEnvioDocumentacaoSeguro
  ): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/envio-documentacao-seguro`, {
      ...body,
    });
  }

  public criarRenovacaoCadastroEmpresa(
    body: PayloadRenovacaoCadastroEmpresa
  ): AxiosObservable<Pedido> {
    return this.axios.post(`${this.backUrl}/renovacao-cadastro-empresa`, {
      ...body,
    });
  }

  public uploadDocument(
    pedidoID: number,
    body: InputUpoloadDocumento
  ): AxiosObservable<any> {
    let formData = new FormData();
    formData.append("file", body.file);
    formData.append("type", body.type);
    // formData.append("dateOfIssue", body.dateOfIssue);
    // formData.append("dateExpiration", body.dateExpiration);
    // formData.append("agencyShipping", body.agencyShipping);
    // formData.append("code", body.code);

    return this.axios.post(
      `${this.backUrl}/${pedidoID}/uploadDocument`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: ({total, loaded}) => {
        //   const percentage = Math.round((loaded * 100) / total)
        //   store.commit('cadastroEmpresa/SET_DOCUMENT_FORM',{typeDocumentKey: body.typeDocumentKey, doc: {progress: percentage}})
        // }
      }
    );
  }

  public show(id: number): AxiosObservable<Pedido> {
    return this.axios.post(`${this.graphqlUrl}`, {
      query: print(pedidoShow),
      variables: { id },
    });
  }

  public createPedidos(body: object): AxiosObservable<any> {
    return this.axios.post(`${this.apiUrl}/solicitations/bulks/autovistoria`, body);
  }

  public detailsPedidos(group: string): AxiosObservable<any> {
    return this.axios.post(`${this.apiUrl}/solicitations/?group=${group}`);
  }

  public detailsPedidosByGroup(group: string): AxiosObservable<any> {
    return this.axios.get(`${this.apiUrl}/solicitations/?group=${group}`);
  }

  public detailsListPayments(group: string): AxiosObservable<any> {
    return this.axios.get(
      `${this.apiUrl}/payments/?solicitation_group=${group}`
    );
  }

  public detailsAutovistoria(
    key: string
  ): AxiosObservable<any> {
    return this.axios.get(
      `${this.apiUrl}/inspections/${key}`
    );
  }

  public updateEvidences(key: string, formData: object){
    return this.axios.patch(`${this.apiUrl}/requirements/${key}`, formData)
  }

  public getByKey(key: string){
    return this.axios.get(`${this.apiUrl}/requirements/${key}`)
  }

  public contentDetach(key: string){
    return this.axios.delete(`${this.apiUrl}/requirements/${key}/content_detach`)
  }
}

import PedidoService from "@/services/pedidos.service";
import {
  Pedido,
  PagePedidoInput,
  PayloadCreatePedido,
  PayloadCreateRenovacaoCRLV,
} from "@/models/pedido";
import { Pagination } from "@/api/pagination";
import GlobalStore from "@/store/modules/global";
import { router } from "@/router";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Documento, InputUpoloadDocumento } from "@/models/documento";
import DocumentoService from "@/services/documento.service";
import PagamentoService from "@/services/pagamento.service";
import { PageTipoDocumentoInput } from "@/models/tipo-documento";
import tipoDocumentoService from "@/services/tipo-documento.service";

import axios from "axios";
import { BACK_URL_SERVICE } from "@/commons/config";
import pedidosService from "@/services/pedidos.service";
const api = axios.create({ baseURL: BACK_URL_SERVICE });

export enum TipoDocumentoEnum {
  REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA = "REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA",
  COPIA_CRLV = "COPIA_CRLV",
  APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA = "APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA",
  LAUDO_TECNICO = "LAUDO_TECNICO",
  ART = "ART",
  VISTORIA = "VISTORIA",
}

export const pedido = {
  namespaced: true,
  state: {
    table: {
      header: [
        {
          text: "Número",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Grupo",
          align: "start",
          sortable: true,
          value: "group",
        },
        {
          text: "Veículo",
          align: "start",
          sortable: true,
          value: "placa",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "typeSolicitation.name",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Data do pedido",
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      content: [] as Array<Pedido>,
      paginate: {
        input: {
          type: null as null,
          status: null as null,
          group: null as null,
        },
        inputPage: {
          page: 1,
          size: 10,
          order: "id",
          descingOrder: true,
        },
      },
      isLoading: false,
      total: 0,
    },
    form: {
      isLoading: false,
      currentStep: 1,
      pedido: {
        typeSolicitation: null as string,
        veiculoTransportadoraId: null as string,
        placa: null as string,
      },
      documents: [] as Array<any>,
    },
    detail: {
      data: null as Pedido,
      isLoading: true,
    },
  },
  mutations: {
    setTableIsLoading(state: any, value: boolean): void {
      state.table.isLoading = value;
    },
    setTableContent(state: any, value: Pedido[]): void {
      state.table.content = value;
    },
    setTableTotal(state: any, value: number): void {
      state.table.total = value;
    },
    setTablePaginate(state: any, value: PagePedidoInput): void {
      state.table.paginate = value;
    },
    setFormIsLoading(state: any, value: boolean): void {
      state.form.isLoading = value;
    },
    setFormPedido(state: any, value: object): void {
      state.form.pedido = { ...value };
    },
    setFormCurrentStep(state: any, value: number): void {
      state.form.currentStep = value;
    },
    setFormDocumentos(state: any, value: Array<object>): void {
      state.form.documents = value;
    },
    setDetail(state: any, value: Pedido): void {
      state.detail.data = value;
    },
    setDetailIsLoading(state: any, value: boolean): void {
      state.detail.isLoading = value;
    },
    SET_DOCUMENT_FORM: (state: any, { typeDocumentKey, doc }: any) => {
      const docs = state.form.documents.map((d: any) => {
        return d.typeDocumentKey === typeDocumentKey ? { ...d, ...doc } : d;
      });
      state.form.documents = docs;
    },
    SET_DOCUMENTS_DETAIL: (state: any, docs: Array<any>) => {
      state.detail.data.documents = docs;
    },
    SET_PAYMENTS_GROUP: (state: any, payments: Array<any>) => {
      state.detail.data.payments = payments;
    },
    SET_PEDIDOS_GROUP: (state: any, pedidosGrupo: Array<any>) => {
      state.detail.data.pedidosGrupo = pedidosGrupo;
    },
  },
  actions: {
    SEARCH({ commit, dispatch, state }: any): void {
      commit("setTableIsLoading", true);
      PedidoService.page(state.table.paginate).subscribe(
        ({ items, totalItems }) => {
          commit("setTableContent", items);
          commit("setTableTotal", totalItems);
          commit("setTableIsLoading", false);
        },
        (error) => {
          commit("setTableIsLoading", false);
        }
      );
    },
    CHANGE_PAGE(
      { commit, dispatch, state }: any,
      value: PagePedidoInput
    ): void {
      commit("setTablePaginate", value);
    },
    CLEAR({ commit }: any): void {
      commit("setTableContent", []);
      commit("setTableTotal", 0);
      commit("setTableIsLoading", false);
      commit("setTablePaginate", {
        input: {
          type: "",
          status: "",
        },
        inputPage: {
          page: 1,
          size: 10,
          order: "id",
          descingOrder: true,
        },
      });
    },
    async CREATE_PEDIDO(
      { commit, dispatch, state }: any,
      { pedido, documents, payment }: any
    ): Promise<any> {
      commit("setFormIsLoading", true);

      try {
        const pedidoCreated = await PedidoService.create(pedido).toPromise();

        // const files = documents.map(async (document: any) => {
        //   const doc = {
        //     file: document.file,
        //     type: document.type
        //   };

        //   await PedidoService.uploadDocument(pedidoCreated.id, doc);
        // });

        // const documentsCreateds = await Promise.all(files);

        GlobalStore.setSnackBar({
          show: true,
          message: `Pedido criado com sucesso`,
          type: "success",
        });

        commit("setFormIsLoading", false);

        router.push({
          name: "pedidos-detail",
          params: { id: String(pedidoCreated.id) },
        });
      } catch (error) {
        commit("setFormIsLoading", false);
      }
    },
    async CREATE_RENOVACAO_CRLV(
      { commit, dispatch, state }: any,
      body: PayloadCreateRenovacaoCRLV
    ): Promise<any> {
      commit("setFormIsLoading", true);
      try {
        const pedido = await PedidoService.createRenovacaoCrlv(
          body
        ).toPromise();
        commit("setDetail", pedido);
        commit("setFormIsLoading", false);
        commit("setFormCurrentStep", 2);
      } catch (error) {
        commit("setFormIsLoading", false);
      }
    },
    async UPLOAD_DOCUMENTOS(
      { commit, dispatch, state }: any,
      documents: Array<any>
    ): Promise<any> {
      const isInvalid = documents.some((f) => f.file === null);
      dayjs.extend(customParseFormat).locale("pt-BR");

      if (isInvalid) {
        GlobalStore.setSnackBar({
          show: true,
          message: "Faça o upload de todos os arquivos",
          type: "warning",
        });
      } else {
        commit("setFormIsLoading", true);
        try {
          const files = documents.map(async (document) => {
            const doc = {
              ...document,
              dateOfIssue: document.dateOfIssue
                ? dayjs(`${document.dateOfIssue}`, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              dateExpiration: document.dateExpiration
                ? dayjs(`${document.dateExpiration}`, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
            };

            await PedidoService.uploadDocument(state.detail.data.id, doc);
          });

          await Promise.all(files);

          GlobalStore.setSnackBar({
            show: true,
            message: `Documentos adicionados com sucesso`,
            type: "success",
          });

          commit("setFormIsLoading", false);

          router.push({ name: "pedidos-list" });
        } catch (error) {
          commit("setFormIsLoading", false);
          GlobalStore.setSnackBar({
            show: true,
            message: `Um erro ocorreu ao tentar fazer o upload dos arquivos. Detalhe: ${error}`,
            type: "warning",
          });
        }
      }
    },
    async UPLOAD_DOCUMENTOS_CRLV(
      { commit, dispatch, state }: any,
      documents: Array<any>
    ): Promise<any> {
      const isInvalid = documents.some((f) => f.file === null);
      dayjs.extend(customParseFormat).locale("pt-BR");

      if (isInvalid) {
        GlobalStore.setSnackBar({
          show: true,
          message: "Faça o upload de todos os arquivos",
          type: "warning",
        });
      } else {
        commit("setFormIsLoading", true);
        try {
          // upload REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA
          const REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA =
            filterPorTipoDocumento(
              documents,
              TipoDocumentoEnum.REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA
            );
          if (REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA) {
            await PedidoService.uploadDocument(
              state.detail.data.id,
              REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA
            );
          }

          // upload COPIA_CRLV
          const COPIA_CRLV = filterPorTipoDocumento(
            documents,
            TipoDocumentoEnum.COPIA_CRLV
          );
          if (COPIA_CRLV) {
            await PedidoService.uploadDocument(
              state.detail.data.id,
              COPIA_CRLV
            );
          }

          // upload APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA
          const APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA =
            filterPorTipoDocumento(
              documents,
              TipoDocumentoEnum.APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA
            );
          if (APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA) {
            await PedidoService.uploadDocument(
              state.detail.data.id,
              APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA
            );
          }

          // upload LAUDO_TECNICO
          const LAUDO_TECNICO = filterPorTipoDocumento(
            documents,
            TipoDocumentoEnum.LAUDO_TECNICO
          );
          if (LAUDO_TECNICO) {
            await PedidoService.uploadDocument(
              state.detail.data.id,
              LAUDO_TECNICO
            );
          }

          // upload ART
          const ART = filterPorTipoDocumento(documents, TipoDocumentoEnum.ART);
          if (ART) {
            await PedidoService.uploadDocument(state.detail.data.id, ART);
          }

          // upload VISTORIA
          const VISTORIA = filterPorTipoDocumento(
            documents,
            TipoDocumentoEnum.VISTORIA
          );
          if (VISTORIA) {
            await PedidoService.uploadDocument(state.detail.data.id, VISTORIA);
          }

          // atualiza pedido
          dispatch("SHOW", state.detail.data.id);

          commit("setFormIsLoading", false);
          router.currentRoute.name === "renovacao-registro-veiculo"
            ? commit("setFormCurrentStep", 3)
            : router.push({
                name: "pedidos-detail",
                params: { id: state.detail.data.id },
              });
        } catch (error) {
          commit("setFormIsLoading", false);
          GlobalStore.setSnackBar({
            show: true,
            message: `Um erro ocorreu ao tentar fazer o upload dos arquivos. Detalhe: ${error.message}`,
            type: "warning",
          });
        }
      }
    },
    CLEAR_FORM_CREATE_PEDIDO({ commit }: any) {
      commit("setFormIsLoading", false);
      commit("setFormPedido", {
        pedido: {
          typeSolicitation: null as string,
          veiculoTransportadoraId: null as string,
          placa: null as string,
        },
      });
      commit("setFormCurrentStep", 1);
      commit("setFormDocumentos", [
        {
          file: null as File,
          type: "REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA",
          dateOfIssue: "",
          dateExpiration: "",
          agencyShipping: "",
          code: "",
        },
        {
          file: null as File,
          type: "COPIA_CRLV",
          dateOfIssue: "",
          dateExpiration: "",
          agencyShipping: "",
          code: "",
        },
        {
          file: null as File,
          type: "APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA",
          dateOfIssue: "",
          dateExpiration: "",
          agencyShipping: "",
          code: "",
        },
        {
          file: null as File,
          type: "LAUDO_TECNICO",
          dateOfIssue: "",
          dateExpiration: "",
          agencyShipping: "",
          code: "",
        },
        {
          file: null as File,
          type: "ART",
          dateOfIssue: "",
          dateExpiration: "",
          agencyShipping: "",
          code: "",
        },
        {
          file: null as File,
          type: "VISTORIA",
          dateOfIssue: "",
          dateExpiration: "",
          agencyShipping: "",
          code: "",
        },
      ]);
    },
    SHOW({ commit, dispatch, state }: any, value: number): void {
      commit("setDetailIsLoading", true);
      PedidoService.show(value).subscribe(
        (res) => {
          if (res) {
            commit("setDetail", res);
            commit("setDetailIsLoading", false);

            const paginateAccomp = {
              input: {
                solicitationId: res.id,
              },
              inputPage: {
                page: 1,
                size: 200,
                order: "createdAt",
                descingOrder: true,
              },
            };
            dispatch(
              "acompanhamentos/changePageAcompanhamento",
              paginateAccomp,
              { root: true }
            );
            dispatch("acompanhamentos/pageAcompanhamentos", null, {
              root: true,
            });

            const paginateDocs = {
              input: {
                name: "",
                solicitationId: Number(res.id),
              },
              inputPage: {
                page: 1,
                size: 200,
                order: "id",
                descingOrder: true,
              },
            };
            DocumentoService.page(paginateDocs).subscribe(
              ({ items }: any) => {
                commit("SET_DOCUMENTS_DETAIL", items);
              },
              (error) => {
                commit("setDetailIsLoading", false);
              }
            );
          } else {
            GlobalStore.setSnackBar({
              show: true,
              type: "warning",
              message: "Pedido não encontrado",
            });
            router.push({ name: "pedidos-list" });
          }
        },
        (error) => {
          commit("setDetailIsLoading", false);
        }
      );
    },
    SHOW_PAYMENTS_GROUP({ commit, dispatch, state }: any, group: string): void {
      pedidosService.detailsListPayments(group).subscribe(
        (res) => {
          if (state.detail.data.status == "PAGAMENTO_PENDENTE") {
            const pendingPayment = res.filter((pay: any) => {
              if (pay.parent == null) {
                pay.group = state.detail.data.group.toUpperCase();
                return pay;
              }
            });
            commit("SET_PAYMENTS_GROUP", pendingPayment);
          } else {
            const paymentConfirmed = res.filter((pay: any) => {
              if (pay.parent != null) {
                pay.group = state.detail.data.group.toUpperCase();
                return pay;
              }
            });
            commit("SET_PAYMENTS_GROUP", paymentConfirmed);
          }
        },
        (err) => {
          commit("setDetailIsLoading", false);
        }
      );
    },
    SHOW_PEDIDOS_GROUP({ commit, dispatch, state }: any, group: string) {
      pedidosService.detailsPedidosByGroup(group).subscribe(
        (res) => {
          commit("SET_PEDIDOS_GROUP", res);
        },
        (err) => {
          commit("setDetailIsLoading", false);
        }
      );
    },
    CLEAR_DETAIL({ commit, dispatch, state }: any): void {
      commit("setDetail", null);
      commit("setDetailIsLoading", false);
    },
    DOWNLOAD_DOCUMENTO(
      { commit, dispatch, state }: any,
      value: Documento
    ): void {
      commit("setDetailIsLoading", true);
      DocumentoService.download(value).subscribe(
        (res) => {
          DocumentoService.doDownloadFile(res, value.name);
          commit("setDetailIsLoading", false);
        },
        (error) => {
          commit("setDetailIsLoading", false);
        }
      );
    },
    CURRENT_STEP({ commit, dispatch, state }: any, value: number): void {
      commit("setFormCurrentStep", value);
    },
    SET_FORM_PEDIDO(
      { commit, dispatch, state }: any,
      value: PayloadCreatePedido
    ): void {
      commit("setFormPedido", value);
    },
    CREATE_PAYMENT({ commit, dispatch, state }: any, data: any): void {
      commit("setDetailIsLoading", true);
      const body = {
        paymentData: {
          transactionAmount: Number(data.amount),
          token: data.token,
          description: state.detail.data.typeSolicitation.name,
          installments: data.installments,
          paymentMethodId: data.paymentMethodId,
          issuerId: data.issuerId,
          payer: {
            firstName: state.detail.data.company.name,
            lastName: state.detail.data.company.name,
            email: data.cardholderEmail,
            identification: {
              type: "CNPJ", // data.identificationType
              number: data.identificationNumber,
            },
          },
        },
        solicitationData: {
          id: state.detail.data.id,
        },
      };
      PagamentoService.create(body).subscribe(
        (res) => {
          commit("setDetailIsLoading", false);
          router.currentRoute.name === "renovacao-crlv"
            ? router.push({
                name: "pedidos-detail",
                params: { id: state.detail.data.id },
              })
            : dispatch("SHOW", state.detail.data.id);
        },
        (err) => {
          commit("setDetailIsLoading", false);
        }
      );
    },
    findTypesDocuments: async (
      { state, commit }: any,
      pagination: PageTipoDocumentoInput
    ) => {
      commit("setDetailIsLoading", true);
      try {
        const { items } = await tipoDocumentoService
          .page(pagination)
          .toPromise();
        const docs = items.map((d) => ({
          id: null,
          typeDocument: d.id,
          typeDocumentName: d.name,
          typeDocumentDescription: d.description,
          typeDocumentKey: d.key,
          file: null,
          filename: null,
          type: null,
          size: 0,
          progress: 0,
          uploaded: false,
          error: false,
        }));
        commit("setFormDocumentos", docs);
        commit("setDetailIsLoading", false);
      } catch (error) {
        commit("setDetailIsLoading", false);
      }
    },
    uploadDocument: async (
      { state, commit }: any,
      { currentDocument, file }: any
    ) => {
      //commit('setDetailIsLoading', true)
      try {
        const document = {
          solicitation: state.detail.data.id,
          file: file,
          filename: file.name,
          type: file.type,
          size: file.size,
          error: false,
          progress: 0,
          uploaded: false,
        };
        commit("SET_DOCUMENT_FORM", {
          typeDocumentKey: currentDocument.typeDocumentKey,
          doc: document,
        });
        const body = state.form.documents.find(
          (d: any) => d.typeDocumentKey === currentDocument.typeDocumentKey
        );

        let formData = new FormData();
        formData.append("file", body.file);
        formData.append(
          "typeDocument",
          `${
            typeof body.typeDocument === "object"
              ? body.typeDocument.id
              : body.typeDocument
          }`
        );
        formData.append("solicitation", `${body.solicitation}`);
        const { data } = await api.post(`/documents`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: ({ total, loaded }: any) => {
            const percentage = Math.round((loaded * 100) / total);
            commit("SET_DOCUMENT_FORM", {
              typeDocumentKey: body.typeDocumentKey,
              doc: { progress: percentage },
            });
          },
        });
        commit("SET_DOCUMENT_FORM", {
          typeDocumentKey: currentDocument.typeDocumentKey,
          doc: { id: data.id, uploaded: true, error: false },
        });
        //commit('setDetailIsLoading', false)
      } catch (error) {
        const document = { error: true };
        commit("SET_DOCUMENT_FORM", {
          typeDocumentKey: currentDocument.typeDocumentKey,
          doc: document,
        });
        //commit('setDetailIsLoading', false)
      }
    },
  },
};

const filterPorTipoDocumento = (
  documentos: InputUpoloadDocumento[],
  tipoDocumento: TipoDocumentoEnum
): InputUpoloadDocumento => {
  return documentos
    .filter((d) => d.type === String(tipoDocumento))
    .map((d) => ({ ...d }))[0];
};

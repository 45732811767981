import { print } from "graphql";
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagination } from './pagination';
import { TipoPedido, PageTipoPedidoInput } from '@/models/tipo-pedido';
import { TipoDocumento } from '@/models/tipo-documento';

const tipoDocumentoPage  = require('@/query/tipo-documento/Page.graphql')

export class TipoDocumentoAPI extends BaseAPI<TipoDocumento> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/type-document`;
  }


  public page(
    input: PageTipoPedidoInput
  ): AxiosObservable<Pagination<TipoDocumento>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(tipoDocumentoPage),
        variables: { ...input }
      })
  }

}

import { Pedido } from '@/models/pedido'

interface CadastroPedidoForm{
  currentStep: number;
  isLoading: boolean;
  data: Pedido;
  documentos: Array<any>;
  error: any;
}

export const initialStateCadastroPedidoForm: CadastroPedidoForm = {
  currentStep: 1,
  isLoading: false,
  data: null,
  documentos: [],
  error: null,
}

export default {
  form: initialStateCadastroPedidoForm
}

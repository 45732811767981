export enum StatusPayment {
  PENDENTE = 'Pendente',
  CONFIRMADO = 'Confirmado',
  CANCELADO = 'Cancelado',
  REJEITADO = "Rejeitado",
  pending = 'Pendente',
  confirmed = 'Confirmado',
  canceled = 'Cancelado'

}


import { Component, Vue } from "vue-property-decorator";

@Component({ name: "app-side-bar" })
export default class AppSideBar extends Vue {
  itemsMenu: object[] = [
    { title: "Início", icon: "home", to: "/inicio" },
    { title: "Meus Pedidos", icon: "assignment_return", to: "/pedidos" }
  ];
}

<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="show"
      :timeout="timeout"
      :multi-line="true"
      :color="'cyan darken-2'"
    >
      <v-icon color="#fff">info</v-icon>
      <strong style="font-size: 14px; margin-left: 5px"
        >Uma nova versão está disponível.
        <a
          href="#"
          @click="show = false"
          style="color: #fff; text-decorator: none; font-weight: bold"
          >Clique Aqui!</a
        ></strong
      >
      <template v-slot:action>
        <v-btn text @click="show = false">
          <v-btn text dark @click.native="show = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import GlobalStore from "@/store/modules/global";

export default Vue.extend({
  computed: {
    show: {
      get: () => GlobalStore.snackbarUpdate.show,
      set: (show) => {
        GlobalStore.setSnackBarUpdate({ show });
        window.location.reload();
      },
    },
    timeout: () => GlobalStore.snackbarUpdate.timeout,
  },
});
</script>

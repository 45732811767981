import PedidoService from "@/services/pedidos.service"
import TipoDocumento from "@/services/tipo-documento.service"
import PagamentoService from "@/services/pagamento.service"
import { PageTipoDocumentoInput } from '@/models/tipo-documento';
import axios from "axios"
import { BACK_URL_SERVICE } from '@/commons/config';
import GlobalStore from "@/store/modules/global"
const api = axios.create({baseURL: BACK_URL_SERVICE})

import {router} from '@/router'

export default {
  findTypesDocuments: async ({state, commit}: any, pagination: PageTipoDocumentoInput) => {
    commit('SET_IS_LOADING_FORM', true)
    try {
      const {items} = await TipoDocumento.page(pagination).toPromise()
      const docs = items.map(d=>({id: null, typeDocument: d.id, typeDocumentName:d.name, typeDocumentDescription: d.description, typeDocumentKey: d.key, file: null, filename: null, type: null, size: 0, progress: 0, uploaded: false, error: false }))
      commit('SET_DOCUMENTS_FORM', docs)
      commit('SET_IS_LOADING_FORM', false)
    } catch (error) {
      commit('SET_IS_LOADING_FORM', false)
    }
  },
  createEnvioDocumentacaoSeguro: async ({state, commit}: any, data: any) => {
    commit('SET_IS_LOADING_FORM', true)
    try {
      const res = await PedidoService.criarEnvioDocumentacaoSeguro(data).toPromise()
      commit('SET_DATA_FORM', res)
      commit('SET_IS_LOADING_FORM', false)
      commit('SET_CURRENT_STEP_FORM', 2)
    } catch (error) {
      commit('SET_IS_LOADING_FORM', false)
    }
  },
  uploadDocument: async ({state, commit}: any, {currentDocument, file}: any) => {
    commit('SET_IS_LOADING_FORM', true)
    try {
      const document = {solicitation: state.form.data.id, file: file, filename: file.name, type: file.type, size: file.size, error: false, progress: 0, uploaded: false }
      commit('SET_DOCUMENT_FORM', {typeDocumentKey: currentDocument.typeDocumentKey, doc: document})
      const body = state.form.documentos.find((d: any)=>d.typeDocumentKey===currentDocument.typeDocumentKey)
      let formData = new FormData()
      formData.append("file", body.file);
      formData.append("typeDocument", `${body.typeDocument}`);
      formData.append("solicitation", `${body.solicitation}`);
      const {data} =  await api.post(`/documents`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: ({total, loaded}) => {
          const percentage = Math.round((loaded * 100) / total)
          commit('SET_DOCUMENT_FORM',{typeDocumentKey: body.typeDocumentKey, doc: {progress: percentage}})
        }
      })
      commit('SET_DOCUMENT_FORM', { typeDocumentKey: currentDocument.typeDocumentKey, doc: { id: data.id, uploaded: true, error: false } })
      commit('SET_IS_LOADING_FORM', false)
    } catch (error) {
      const document = { error: true }
      commit('SET_DOCUMENT_FORM', {typeDocumentKey: currentDocument.typeDocumentKey, doc: document})
      commit('SET_ERROR_FORM', error)
      commit('SET_IS_LOADING_FORM', false)
    }
  },
  changeStepForm: ({state, commit}: any, value: number) => {
    commit('SET_CURRENT_STEP_FORM', value)
  },
  doPayment: async ({state, commit}: any, value: any) => {
    commit('SET_IS_LOADING_FORM', true)
    try {
      const body = {
        paymentData: {
          transactionAmount: Number(value.amount),
          token: value.token,
          description: state.form.data.typeSolicitation.name,
          installments: value.installments,
          paymentMethodId: value.paymentMethodId,
          issuerId: value.issuerId,
          payer: {
            firstName: state.form.data.company.name,
            lastName: state.form.data.company.name,
            email: value.cardholderEmail,
            identification: {
              type: 'CNPJ', // data.identificationType
              number: value.identificationNumber
            }
          }
        },
        solicitationData: {
          id: state.form.data.id
        }
      };
      await PagamentoService.create(body).toPromise()
      GlobalStore.setSnackBar({
        show: true,
        message: "Pedido criado com sucesso",
        type: "success",
        timeout: 10000
      });
      router.push({name: 'pedidos-detail', params: {id: state.form.data.id}})
      // commit('SET_IS_LOADING_FORM', false)
      // commit('SET_CLEAR_FORM')
    } catch (error) {
      commit('SET_ERROR_FORM', error)
      commit('SET_IS_LOADING_FORM', false)
    }
  },
  clearForm: ({state, commit}: any) => {
    commit('SET_CLEAR_FORM')
  }
}

import { router } from '@/router'
import { Auth } from '@/models/auth'
import { User } from '@/models/user'
import { UserAPI } from '@/api/user.api'
import { BASE_PATH_APP, APP_PREFIX_AUTH } from '@/commons/config'

import JwtDecode from 'jwt-decode'
import GlobalStore from '@/store/modules/global'

import { map } from 'rxjs/operators'
import { BehaviorSubject, Observable } from 'rxjs'

const AUTH = `${APP_PREFIX_AUTH}@auth`
const userApi = new UserAPI()

const currentUser = () => {
  try {
    const { token = null } = JSON.parse(localStorage.getItem(AUTH)) || {}

    if (!token) return null

    const { id, name, cnpj } = JwtDecode(token)
    return new User(id, name, cnpj)
  } catch (err) {
    localStorage.removeItem(AUTH)
    router.push({name: 'login'})
    return null
  }
}

const userSubject = new BehaviorSubject(currentUser())

userSubject.subscribe(user => {
  if (GlobalStore) {
    GlobalStore.setUser(user)
  }
})

const login = (cnpj: string, token: string, concordo: boolean, returnUrl?: string): Observable<User> => {
  return userApi
    .signin(cnpj, token, concordo)
    .pipe(map(response => setSession(response, returnUrl)))
}

const logout = (): void => {
  localStorage.removeItem(AUTH)
  userSubject.next(null)
  setTimeout(()=>{router.push({name: 'login'})}, 100)
}

const setSession = (authResult: any, returnUrl: any): User => {
  const {email} = JwtDecode(authResult.token)
  buildAuth(authResult)
  userSubject.next(currentUser())

  if(email) {
    router.push(returnUrl || '/')

    return userSubject.value
  } else {
    router.push({name: 'confirmar-email'})
  }
}

const buildAuth = ({ token }: Auth) => {
  localStorage.setItem(AUTH, JSON.stringify({ token }))
}

const currentToken = () => {
  const { token = null } = JSON.parse(localStorage.getItem(AUTH)) || {}
  return token
}

export default {
  login,
  logout,
  get currentUserValue () {
    return userSubject.value
  },
  get token () {
    return currentToken()
  }
}

import { Acompanhamento, PageAcompanhamentoInput } from '@/models/acompanhamento';
import AcompService from "@/services/acompanhamento.service"
import { Pagination } from '@/api/pagination';

const initialState = {
  data: [] as Array<Acompanhamento>,
  isLoading: false,
  errors: [] as Array<any>,
  paginate: {
    input: {
      solicitationId: null as number
    },
    inputPage: {
      page: 1,
      size: 200,
      order: "createdAt",
      descingOrder: true
    }
  } as PageAcompanhamentoInput
}

export const acompanhamentos = {
  namespaced: true,
  state: { acompanhamentos: {...initialState }},
  mutations: {
    SET_ACOMPANHAMENTOS(state: any, value: Array<Acompanhamento>): void {
      state.acompanhamentos.data = value
    },
    SET_ACOMPANHAMENTOS_IS_LOADING(state: any, value: boolean): void {
      state.acompanhamentos.isLoading = value
    },
    SET_ACOMPANHAMENTOS_ERRORS(state: any, value: Array<any>): void {
      state.acompanhamentos.errors = value
    },
    SET_PAGINATE(state: any, value: PageAcompanhamentoInput): void {
      state.acompanhamentos.paginate = value
    },
    SET_CLEAR_ACOMPANHAMENTOS(state: any): void {
      state.acompanhamentos = {
        data: [] as Array<Acompanhamento>,
        isLoading: false,
        errors: [] as Array<any>,
        paginate: {
          input: {
            solicitationId: null as number
          },
          inputPage: {
            page: 1,
            size: 250,
            order: "createdAt",
            descingOrder: true
          }
        } as PageAcompanhamentoInput
      }
    },
  },
  actions: {
    async pageAcompanhamentos({ commit, dispatch, state }: any) {
      commit('SET_ACOMPANHAMENTOS_IS_LOADING', true)
      try {
        const { acompanhamentos: { paginate }}  = state
        const { items } = await AcompService.page(paginate).toPromise()
        commit('SET_ACOMPANHAMENTOS', items)
        commit('SET_ACOMPANHAMENTOS_IS_LOADING', false)
      } catch (error) {
        commit('SET_ACOMPANHAMENTOS_IS_LOADING', false)
        commit('SET_ACOMPANHAMENTOS_ERRORS', error)
      }
    },
    changePageAcompanhamento({ commit, dispatch, state }: any, value: PageAcompanhamentoInput) {
      commit('SET_PAGINATE', value)
    },
    clearAcompanhamentos({ commit, dispatch, state }: any) {
      commit('SET_CLEAR_ACOMPANHAMENTOS')
    }
  }
}

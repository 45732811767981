<template>
  <div>
    <v-menu
      bottom
      offset-y
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon dark v-bind="attrs" v-on="on">
          <v-icon>support</v-icon>
        </v-btn>
      </template>
      <section class="app-contact">
        <article class="app-contact-content">
          <h5 class="app-contact-content__title">Telefone/WhatsApp</h5>
          <a href="tel:(85) 30937801" class="app-contact-content__link"
            >(85) 3093-7801</a
          >
        </article>
        <v-divider></v-divider>
        <article class="app-contact-content">
          <h5 class="app-contact-content__title">Email</h5>
          <a
            href="mailto:atendimento@sistransce.com.br"
            class="app-contact-content__link"
            >atendimento@sistransce.com.br</a
          >
        </article>
      </section>
    </v-menu>
    <v-menu right bottom>
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>

      <v-list class="pa-0">
        <v-list-item href="#">
          <v-list-item-action>
            <v-icon>account_circle</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="(item, index) in items"
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          @click="item.click"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          rel="noopener"
          :key="index"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Vue from "vue";
import GlobalStore from "@/store/modules/global";
import AuthenticationService from "@/services/authentication.service";

export default Vue.extend({
  name: "app-menu",
  data: () => ({
    items: [
      // {
      //   icon: "settings",
      //   href: "#",
      //   title: "Definições",
      //   click: e => {}
      // },
      {
        icon: "exit_to_app",
        href: "#",
        title: "Sair",
        click: e => {
          AuthenticationService.logout();
          GlobalStore.setSnackBar({
            show: true,
            message: "Saiu do sistema com sucesso.",
            type: "success"
          });
        }
      }
    ]
  }),
  computed: {
    user: () => GlobalStore.user || {}
  }
});
</script>

<style lang="scss" scoped>
.app-contact {
  background-color: #fff;
  &-content {
    padding: 10px;
    &__title {
      margin: 0px;
      font-weight: bold !important;
      color: #636363;
    }
    &__link {
      color: #636363;
    }
  }
}
</style>

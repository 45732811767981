import Vue from 'vue'
import Router from 'vue-router'
import GlobalStore from '@/store/modules/global'
import { BASE_PATH_APP } from '@/commons/config'

Vue.use(Router)

export const router = new Router({
  base: BASE_PATH_APP,
  routes: [
    {
      path: '/',
      redirect: {
        path: '/inicio'
      }
    },
    {
      path: '/inicio',
      name: 'home',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: 'Inicio'
      },
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: { publicPage: true },
      beforeEnter: (to: any, from: any, next: Function):void => {
        const currentUser = GlobalStore.user
        if(to.name==='login' && currentUser) {
          return next({ name: 'home'})
        }

        return next()
      },
      component: () =>
        import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/confirmar-email',
      name: 'confirmar-email',
      meta: { publicPage: true },
      component: () =>
        import(/* webpackChunkName: "ConfirmEmail" */ './views/ConfirmEmail.vue')
    },
    {
      path: '/cadastro-emprensa',
      name: 'cadastro-emprensa',
      meta: {
        publicPage: true,
      },
      beforeEnter: (to: any, from: any, next: Function):void => {
        const currentUser = GlobalStore.user
        if(to.name==='cadastro-emprensa' && currentUser) {
          return next({ name: 'home'})
        }

        return next()
      },
      component: () => import(/* webpackChunkName: "cadastro-emprensa" */ './views/cadastro-empresa/index.vue')
    },
    {
      path: '/renovacao-registro-veiculo',
      name: 'renovacao-registro-veiculo',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Renovação do Registro de Veículo"
      },
      component: () => import(/* webpackChunkName: "renovacao-crlv" */ './views/renovacao-registro-veiculo/index.vue')
    },
    {
      path: '/inclusao-veiculo',
      name: 'inclusao-veiculo',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Inclusão de Veículo"
      },
      component: () => import(/* webpackChunkName: "inclusao-veiculo" */ './views/inclusao-veiculo/index.vue')
    },
    {
      path: '/exclusao-veiculo',
      name: 'exclusao-veiculo',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Exclusão Veículo"
      },
      component: () => import(/* webpackChunkName: "exclusao-veiculo" */ './views/exclusao-veiculo/index.vue')
    },
    {
      path: '/autovistoria-laudo-engenheiro-mecaninco',
      name: 'autovistoria-laudo-engenheiro-mecaninco',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Autovistoria + Laudo Engenheiro Mecânico"
      },
      component: () => import(/* webpackChunkName: "autovistoria" */ './views/autovistoria/index.vue')
    },
    {
      path: '/renovacao-cadastro-empresa',
      name: 'renovacao-cadastro-empresa',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Renovação Cadastro Empresa"
      },
      component: () => import(/* webpackChunkName: "renovacao-cadastro-empresa" */ './views/renovacao-cadastro-empresa/index.vue')
    },
    {
      path: '/envio-documentacao-seguro',
      name: 'envio-documentacao-seguro',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Envio Documentação Seguro"
      },
      component: () => import(/* webpackChunkName: "envio-documentacao-seguro" */ './views/envio-documentacao-seguro/index.vue')
    },
    {
      path: '/renovacao-certificado-tacografo',
      name: 'renovacao-certificado-tacografo',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Renovação Certificado Tacógrafo"
      },
      component: () => import(/* webpackChunkName: "renovacao-certificado-tacografo" */ './views/renovacao-certificado-tacografo/index.vue')
    },
    {
      path: '/pedidos',
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: 'Pedidos'
      },
      component: { render: (h: any) => h("router-view") },
      children: [
        {
          path: '',
          name: 'pedidos-list',
          meta: {
            publicPage: false,
            authorize: [],
          },
          component: () => import(/* webpackChunkName: "pedidos-list" */ './views/pedidos/List.vue')
        },
        {
          path: ':id',
          name: 'pedidos-detail',
          meta: {
            publicPage: false,
            authorize: [],
            breadcrumb: 'Detalhe'
          },
          component: () => import(/* webpackChunkName: "pedidos-detail" */ './views/pedidos/Detail.vue')
        },
      ]
    },
    {
      path: '*',
      meta: { publicPage: true },
      redirect: {
        path: '/404'
      }
    },
    {
      path: '/404',
      name: 'not-found',
      meta: { publicPage: true },
      component: () =>
        import(/* webpackChunkName: "notFound" */ './views/NotFound.vue')
    },
    {
      path: '/403',
      meta: { publicPage: true },
      name: 'denied',
      component: () =>
        import(/* webpackChunkName: "accessDenied" */ `@/views/Deny.vue`)
    },
    {
      path:"/autovistoria/:key",
      name:"formulario",
      meta: { publicPage: true },
      component: () => import( /* webpackChunkName: "form-evidencias" */ "./views/pedidos/autovistoria/FormEvidencias.vue")
    },
  ]
})

router.beforeEach((to, from, next) => {
  const { authorize, publicPage } = to.meta
  const currentUser = GlobalStore.user

  if (!publicPage && authorize) {
    if (!currentUser) {
      return next({ name: 'login', query: { returnUrl: to.path } })
    }

    // if (authorize.length && !GlobalStore.functionalities.some(f => authorize.includes(f))) {
    //   return next({ name: 'denied' })
    // }
  }

  next()
})

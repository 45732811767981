import Vue from 'vue'
import Vuex from 'vuex'

import { pedido } from "./modules/pedido"
import { acompanhamentos } from "./modules/acompanhamentos"

import cadastroEmpresa from './modules/cadastro-empresa'
import renovacaoRegistroVeiculo from './modules/renovacao-registro-veiculo'
import inclusaoVeiculo from './modules/inclusao-veiculo'
import exclusaoVeiculo from './modules/exclusao-veiculo'
import autovistoria from './modules/autovistoria'
import laudoEngenheiroMecanico from './modules/laudo-engenheiro-mecanico'
import envioDocumentacaoSeguro from './modules/envio-documentacao-seguro'
import renovacaoCadastroEmpresa from './modules/renovacao-cadastro-empresa'
import renovacaoCertificadoTacografo from './modules/renovacao-certificado-tacografo'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    pedido,
    acompanhamentos,
    cadastroEmpresa,
    renovacaoRegistroVeiculo,
    inclusaoVeiculo,
    exclusaoVeiculo,
    autovistoria,
    laudoEngenheiroMecanico,
    envioDocumentacaoSeguro,
    renovacaoCadastroEmpresa,
    renovacaoCertificadoTacografo
  }
})

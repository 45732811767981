import PedidoService from "@/services/pedidos.service";
import TipoDocumento from "@/services/tipo-documento.service";
import PagamentoService from "@/services/pagamento.service";
import { PageTipoDocumentoInput } from "@/models/tipo-documento";
import axios from "axios";
import { BACK_URL_SERVICE, API_URL_SERVICE } from "@/commons/config";
import GlobalStore from "@/store/modules/global";
// import PedidoStore from "@/store/modules/pedido";
const api = axios.create({ baseURL: BACK_URL_SERVICE });

import { router } from "@/router";
import pedidosService from "@/services/pedidos.service";
import { Pagination } from "@/api/pagination";
import { dispatch } from "rxjs/internal/observable/pairs";

export default {
  createPedidos: async ({ state, commit, dispatch }: any, data: any) => {
    commit("SET_DATA_FORM", ...data.typeSolicitation);
    try {
      commit("SET_IS_LOADING_FORM", true);

      const res = await pedidosService
        .createPedidos({
          kind: "add",
          content: data.placas,
        })
        .toPromise();

      commit("SET_FORM_VEICULOS", res);
      commit("SET_IS_LOADING_FORM", false);
      commit("SET_CURRENT_STEP_FORM", 2);
      // dispatch("findTypesDocuments");
    } catch (error) {
      GlobalStore.setSnackBar({
        show: true,
        message: `${error.response.data[0].errors}`,
        type: "error",
        timeout: 10000,
      });
    }
  },
  findTypesDocuments: async (
    { state, commit }: any,
    pagination: PageTipoDocumentoInput
  ) => {
    commit("SET_IS_LOADING_FORM", true);
    try {
      const { items } = await TipoDocumento.page(pagination).toPromise();
      const docs = items.map((d) => ({
        id: null,
        typeDocument: d.id,
        typeDocumentName: d.name,
        typeDocumentDescription: d.description,
        typeDocumentKey: d.key,
        file: null,
        filename: null,
        type: null,
        size: 0,
        progress: 0,
        uploaded: false,
        error: false,
      }));
      const docsPorVeiculo = state.form.placas.map(
        (placa: any, index: any) => ({
          placa: placa.placa,
          group: placa.group,
          id: placa.id,
          documentos: docs,
          typeSolicitation: state.form.data,
        })
      );
      commit("SET_FORM_DOC", docsPorVeiculo);
      commit("SET_DOCUMENTS_FORM", docs);
      commit("SET_IS_LOADING_FORM", false);
    } catch (error) {
      commit("SET_IS_LOADING_FORM", false);
    }
  },
  uploadDocument: async (
    { state, commit }: any,
    { currentDocument, file, id }: any
  ) => {
    commit("SET_IS_LOADING_FORM", true);
    try {
      const document = {
        id,
        file: file,
        filename: file.name,
        type: file.type,
        size: file.size,
        error: false,
        progress: 0,
        uploaded: false,
      };
      const searchDocPlaca = state.form.veiculosDocumentos.find(
        (res: any) => res.id == id
      );
      const body = searchDocPlaca.documentos.find(
        (d: any) => d.typeDocumentKey === currentDocument.typeDocumentKey
      );
      let formData = new FormData();
      formData.append("file", body.file);
      formData.append("typeDocument", `${body.typeDocument}`);
      formData.append("solicitation", `${body.solicitation}`);

      const { data } = await api.post(`/documents`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: ({ total, loaded }) => {
          const percentage = Math.round((loaded * 100) / total);
          body.progress = percentage;
        },
      });

      body.id = data.id;
      body.uploaded = true;
      body.error = false;

      commit("SET_IS_LOADING_FORM", false);
    } catch (error) {
      const document = { error: true };
      const searchDocPlaca = state.form.veiculosDocumentos.find(
        (res: any) => res.id == id
      );
      const body = searchDocPlaca.documentos.find(
        (d: any) => d.typeDocumentKey === currentDocument.typeDocumentKey
      );
      body.uploaded = false;
      body.error = true;
      // commit("SET_DOCUMENT_FORM", {
      //   typeDocumentKey: currentDocument.typeDocumentKey,
      //   doc: document,
      // });
      commit("SET_ERROR_FORM", error);
      commit("SET_IS_LOADING_FORM", false);
    }
  },
  changeStepForm: ({ state, commit }: any, value: number) => {
    commit("SET_CURRENT_STEP_FORM", value);
  },
  doPayment: async ({ state, commit }: any, value: any) => {
    commit("SET_IS_LOADING_FORM", true);
    try {
      await PagamentoService.createPaymentGroup(value).toPromise();

      commit("SET_MESSAGE", {
        active: true,
        msg: "Pedido criado com sucesso",
        icon: "task_alt",
        color: "#C8E6C9",
      });

      commit("SET_IS_LOADING_FORM", false);
      // commit('SET_CLEAR_FORM')
    } catch (error) {
      commit("SET_MESSAGE", {
        active: true,
        msg: "Não foi possivel criar o pedido ",
        icon: "error",
        color: "#FF9100",
      });

      commit("SET_ERROR_FORM", error);
      commit("SET_IS_LOADING_FORM", false);
    }
  },
  clearForm: ({ state, commit }: any) => {
    commit("SET_CLEAR_FORM");
  },
  savePlacas: ({ state, commit }: any, value: Array<any>) => {
    commit("SET_FORM_VEICULOS", value);
  },
  addDocumentos: ({ state, commit }: any, value: Array<any>) => {
    commit("SET_FORM_DOC", value);
  },
};

import { TipoDocumentoAPI } from '@/api/tipo-documento.api';
import { Pagination } from '@/api/pagination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoDocumento, PageTipoDocumentoInput } from "@/models/tipo-documento"


const api = new TipoDocumentoAPI();

const page = (input: PageTipoDocumentoInput): Observable<Pagination<TipoDocumento>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageTypesDocuments: { items, totalItems } } = data;
    return { items, ...totalItems }
  }));
}


export default {
  page,
}

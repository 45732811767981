import { Pedido } from "@/models/pedido";

interface InclusaoVeiculoForm {
  currentStep: number;
  isLoading: boolean;
  data: Pedido;
  documentos: Array<any>;
  error: any;
  veiculosDocumentos: Array<any>;
  placas:Array<any>;
  message: object
}

export const initialStateInclusaoVeiculoForm: InclusaoVeiculoForm = {
  currentStep: 1,
  isLoading: false,
  data: null,
  documentos: [],
  error: null,
  veiculosDocumentos: [],
  placas:[],
  message: [{active:false, msg:"",icon:"",color:""}]
};

export default {
  form: initialStateInclusaoVeiculoForm,
};

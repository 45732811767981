
import Vue from "vue";
import GlobalStore from "@/store/modules/global";

export default Vue.extend({
  name: "app-snack-bar",
  computed: {
    show: {
      get: () => GlobalStore.snackbar.show,
      set: (show: boolean) => GlobalStore.setSnackBar({ show })
    },
    message: () => GlobalStore.snackbar.message,
    errors: () => {
      const {
        snackbar: { message }
      } = GlobalStore;
      return message ? message.split("\n") : [];
    },
    multiErrors: () => {
      const {
        snackbar: { message }
      } = GlobalStore;
      const errors = message ? message.split("\n") : [];
      return errors.length > 1;
    },
    timeout: () => GlobalStore.snackbar.timeout,
    type: () => GlobalStore.snackbar.type
  }
});
